@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto:wght@300&display=swap");

@import "~react-image-gallery/styles/css/image-gallery.css";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;

  /** Background **/
  --nav-bg-color: antiquewhite;
  --nav-bg-color-semitrans: rgba(250, 235, 215, 0.288);
  --main-bg-color: rgba(255, 255, 255, 0.945);
  --shop-bg-color: rgb(245, 245, 245);
  --item-bg-color: rgb(255, 255, 255);
  --secondary-bg-color: rgba(255, 255, 255, 0.945);
  --sidebar-bg-color: rgba(250, 235, 215, 0.973);
  --footer-bg-color: #101522;

  /** Text **/
  --nav-logo-text-color: rgb(36, 36, 36);
  --nav-text-color: rgb(36, 36, 36);
  --nav-text-color-active: rgb(87, 87, 87);
  --nav-text-color-press: rgb(112, 112, 112);
  --main-text-color: rgb(216, 216, 216);
  --main-text-color-hover: rgb(199, 184, 184);
  --title-text-color: rgb(126, 126, 126);
  --title-text-color-hover: rgb(216, 216, 216);
  --secondary-text-color: rgb(29, 29, 29);
  --secondary-text-color-hover: rgb(199, 184, 184);

  /** Button **/
  --button-primary-color: #fff;
  --button-secondary-color: rgb(36, 36, 36);
  --button-primary-bgcolor: #010606;
  --button-secondary-bgcolor: antiquewhite;
  --button-primary-bgcolor-hover: rgb(54, 54, 54);
  --button-secondary-bgcolor-hover: #4a5f5f;
}

.noscroll {
  overflow: hidden;
}
